

.language-selector-label{
  display: block;
  color: #fff;
  margin-bottom: 9px;
  font-family: "amplitude-light","Arial Narrow",arial;
  font-size: 1.1em;
  line-height: 1.4em;
}
.dropdownColumn{
  margin-top:-198px!important;
}
.language-button-style{
  text-transform: uppercase;
  font-size: 24px!important;
  font-family: "amplitude-light","Arial Narrow",arial!important;
  width: 200px;
  height: 50px!important;
  margin-top: 0px!important;
}
.homeBg{
  width: 100%;
  height: 100%;
  background-color: red;

}
.intro-heading{
  animation-name: fadeIn;
  animation-duration: .6s;
  animation-timing-function: ease-in;
  opacity:1;
}
@keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1;}
}
.intro-para{
  max-width: 846px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 1.5em;
  text-transform: none;
  color: #ffffff;
}

.dropdownContainer{
  margin-top: 75px;
  animation-name: moveIn;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

@keyframes moveIn {
  from {margin-top:0px;opacity: 0}
  to {margin-top:75px;opacity: 1}
}
.language-card{
  background-color: rgba(0,0,0,.10)!important;
  border: none!important;
  max-width: 351px;
  margin: 0 auto;
}
.intro-content{
  padding: 0px;
}
.home-icon{
  color:#ffffff!important;
  margin-right: 10px;
}
.languages-label{
  color:#ffffff!important;
  font-size: 15px;
  font-weight: 500;
  line-height: 2.5;
}

.lang-select-button{
  padding: 25px 0px;
  margin-top: 70px!important;
}
#selectButton{
  background-color: var(--primaryBackground);
  color:var(--selectButtonTextColor);
}
