.align-center{
    margin: 0 auto;
}
.buttonLabel{
  line-height: 0.2em;
}
.select-dropdown{
    width: auto!important;
}
.select-button{
    padding: 30px 0px;
}
.lang-selector button span div span{
  font-size: 19px!important;
  font-family: "amplitude-light","Arial Narrow",arial;
  font-weight: bold;
}
.dropdown div div{
  font-size: 19px!important;
  line-height: 1.5!important;
  font-family: "amplitude-light","Arial Narrow",arial;
}
.accept-btn{
    background-color: #00477B!important;
    color: #ffffff!important;
}
.deny-btn{
    background-color: #A6150B!important;
    color: #ffffff!important;
}
.thin-separator{
  border-top: 1px solid #2670A9;
  width:100%;
  background-color: rgba(227,227,227,0);
  /*animation-name: inlarge;
  animation-duration: 1s;
  animation-timing-function: ease-in;*/
}
