.align-center {
  margin: 0 auto;
}

.dialog-content {
  text-align: center;
  padding-top: 30px !important;
}

.dialog-content div {
  self-align: center;
}

.buttonLabel {
  line-height: 0.2em;
  white-space: nowrap;
}

.select-dropdown {
  width: auto!important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.select-dropdown div button{
  width: auto !important;
  overflow: auto !important;
}

.select-button {
  padding: 30px 0px;
}

.button-style {
  background-color: #C7C8CA !important;
  color: #000000 !important;
  text-transform: uppercase;
  font-size: 24px !important;
  border: 1px solid #C7C8CA !important;
  font-family: "amplitude-light", "Arial Narrow", arial !important;
  width: 200px;
  height: 50px !important;
  margin-top: 0px !important;
}

.download-btn {
  background-color: #2670a9 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  margin-top: -275px !important;
  padding: 5px 15px 5px 15px;
  text-decoration: none;
  pointer-events: auto;
  display: block;
}

.download-btn-disabled {
  background-color: #bababa !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  margin-top: -275px !important;
  padding: 5px 15px 5px 15px;
  text-decoration: none;
  pointer-events: none;
  display: none;
}

.download-csv-btn {
  background-color: #2670a9 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  margin-top: 5px !important;
  padding: 5px 15px 5px 15px;
  text-decoration: none;
  pointer-events: auto;
  display: block;
}

.download-btn:focus {
  outline: 2.5px dotted #2670a9;
}

.language-select {
  margin-top: 2px;
  width: 280px;
}

.label-select {
  margin: 15px 0px 6px 0px;
  font-size: 18px;
}

.dialog-bag {
  border-bottom: 8px solid #18723D !important;
}

.dialog-bag div{
  align-self: center!important;
}

.hidden {
  display: none;
}

.showElement {
  display: block;
}

.spinner-style {
  margin: 0 auto;
  width: 30px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.message {
  font-size: 18px;
  display: inline;
}

.found-message {
  font-size: 18px;
  display: inline;
}

.message-icon {
  margin-right: 10px;
  color: #A71C0E !important;
}

.error-text {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.tick-icon {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  color: #18723D !important;
}

.select-dropdown div div div label {
  /*font-size: 25px;*/
}

button span div span {
  font-size: 18px !important;
  font-family: "amplitude-light", "Arial Narrow", arial;
  font-weight: bold;
}

.hide-pdf-icon {
  display: none !important;
}

.show-pdf-icon {
  display: inline-block !important;
}

.thin-error-separator {
  border-top: 2px solid #2670A9;
  width: 80%;
  background-color: rgba(227, 227, 227, 0);
  animation-name: inlarge;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

.hide-button {
  display: none;
}

.show-history {
  display: block;
}

.hide-history {
  display: none;
}

.history-button-header {
  margin-top: -94px;
  text-align: center;
  border-top: 1px solid grey;
  padding-top: 8px;
}

#pdf-dialog div div{
  width:auto;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .download-btn {
    background-color: #2670a9 !important;
    color: #ffffff !important;
    margin : 0 15em 0 !important;
    /*margin-top: -275px !important;*/
    padding: 5px 15px 5px 15px;
    text-decoration: none;
    pointer-events: auto;
    display: block;
  }
  .download-csv-btn {
    background-color: #2670a9 !important;
    color: #ffffff !important;
    margin: 0px 206.8px 0.8px !important;
    /*margin-top: 5px !important;*/
    padding: 5px 15px 5px 15px;
    text-decoration: none;
    pointer-events: auto;
    display: block;
  }
  .history-button-header {
    margin-top: 11px;
    text-align: center;
    border-top: 1px solid grey;
    padding-top: 8px;
  }
}
