body {
  margin: 0;
  padding: 0;
  font-family: amplitude-light, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var( --mainBackground);
  background-image: var( --mainBackgroundImageMoz);
  background-image: var( --mainBackgroundImageMs);
  background-image: var( --mainBackgroundImageLin);
  /*IE Fallback*/
  background-image:-ms-linear-gradient(top,rgba(36,37,38,0.8),rgba(36,37,38,0.6),#000),url(https://469479048363-priips.prod.aws.jpmchase.net/static/media/test-background.a71f4c62.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment:fixed;
  width:100%;
  height:100%;
  display: block;
}

body:before {
  content: "";
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
  background-attachment:fixed;
  width:100%;
  height:100%;
  display: block;
  opacity: 0;
  background-color: #161616;
}
@keyframes zoomIn {
  from {opacity:1;}
  to {opacity:0;}
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
@font-face {
  font-family: "amplitude-regular";
  src: url("fonts/amplitude/Amplitude-Regular/8b10efc3-e37f-468c-9326-3f261bc2b6e9-2.eot");
  src: url("fonts/amplitude/Amplitude-Regular/8b10efc3-e37f-468c-9326-3f261bc2b6e9-2.eot?") format("embedded-opentype"),
  url("fonts/amplitude/Amplitude-Regular/8b10efc3-e37f-468c-9326-3f261bc2b6e9-3.woff") format("woff"),
  url("fonts/amplitude/Amplitude-Regular/8b10efc3-e37f-468c-9326-3f261bc2b6e9-1.ttf") format("truetype"),
  url("fonts/amplitude/Amplitude-Regular/8b10efc3-e37f-468c-9326-3f261bc2b6e9-4.svg#web") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "amplitude-medium";
  src: url("fonts/amplitude/Amplitude-Medium/5c91d7e8-9155-43e0-a01c-f4c8b4ab0369-2.eot");
  src: url("fonts/amplitude/Amplitude-Medium/5c91d7e8-9155-43e0-a01c-f4c8b4ab0369-2.eot?") format("embedded-opentype"),
  url("fonts/amplitude/Amplitude-Medium/5c91d7e8-9155-43e0-a01c-f4c8b4ab0369-3.woff") format("woff"),
  url("fonts/amplitude/Amplitude-Medium/5c91d7e8-9155-43e0-a01c-f4c8b4ab0369-1.ttf") format("truetype"),
  url("fonts/amplitude/Amplitude-Medium/5c91d7e8-9155-43e0-a01c-f4c8b4ab0369-4.svg#web") format("svg");
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: "amplitude-light";
  src: url("fonts/amplitude/Amplitude-Light/ee553ccf-2d0c-4e23-9d9d-1067a902018a-2.eot");
  src: url("fonts/amplitude/Amplitude-Light/ee553ccf-2d0c-4e23-9d9d-1067a902018a-2.eot?") format("embedded-opentype"),
  url("fonts/amplitude/Amplitude-Light/ee553ccf-2d0c-4e23-9d9d-1067a902018a-3.woff") format("woff"),
  url("fonts/amplitude/Amplitude-Light/ee553ccf-2d0c-4e23-9d9d-1067a902018a-1.ttf") format("truetype"),
  url("fonts/amplitude/Amplitude-Light/ee553ccf-2d0c-4e23-9d9d-1067a902018a-4.svg#web") format("svg");
  font-style: normal;
  font-weight: normal;
}
.centerAlign{
  margin: 0 auto;
}
.theme-toggle-button{
  margin: 0 10px 0 10px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .theme-toggle-button{
    visibility: hidden;
    margin: 0 10px 0 10px;
  }
}
