.homeIcon{
    margin-left:91.5%;
}
#main-header{
  background: var(--headerBackground);
  position: fixed;
}

.iewarning{
  visibility: hidden;
  width: 100%;
  min-height: 20px;
  padding-top: 30px;
  background-color: #161616!important;
  display: flex;
}

.iewarning .ietext {
   text-align: center;
   color: #e53935;
   width: inherit;
   padding-top: 10px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .iewarning{
    visibility: visible;
  }
}
