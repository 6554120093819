.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.intro-heading{
  font-size: 54px;
  margin: 0.67em 0;
  font-weight: 100;
}

.intro{
  font-family: "amplitude-light","Arial Narrow",arial;
  line-height: 1.4;
  color:#ffffff;
  text-align: center;
  text-transform: uppercase;
}

.svgLogo{
  width: 130px!important;
  height: 25px!important;
}
/* Common to all*/
.separetor{
  border-top: 5px solid var(--separatorColor);
/*IE Fallback*/
  border-top : 5px solid #e06519 !important;
  width:25%;
  background-color: rgba(227,227,227,0);
  animation-name: inlarge;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}
@keyframes inlarge {
  from {transform:scale(.1);}
  to {transform:scale(1);}
}
