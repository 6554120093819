#panel-rout {
  margin-top: 40px!important;
  padding-bottom: 0px!important;
}
.grid-container > div > div {
  width: 100%;
  margin: 0 auto;
}

.grid-container > div > div > div {
  width: 100%;
}
#data-grid > div > div {
  max-height: 1000px;
  min-height: 600px;
}
.error {
  margin: 0 auto!important;
}

.date-list > div {
  width: 150px!important;
}

.tools-container {
  width: 100%;
  height: 32px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
}

.export-button {
  margin-bottom: 20px;
  float: right;
  background-color: transparent;
}

.date-list {
  width: 120px !important;
  float: left;
}

.export-buttn {
  float: right;
}

.text-cell {
  text-align: left;
}

.hide-tools {
  display: none;
}

.grid-container > div > div > div > .icon {
  margin: 0px!important;
}
