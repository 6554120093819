.footer-nav{
  position: fixed;
  height: 60px;
  background: var(--footerBackground);
  background: #161616!important;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  color: #ffffff;
}
.footer-links{
  vertical-align: middle;
  line-height: 50px;
  float: right;
  margin-right: 100px;
  color: #ffffff;
  text-decoration: none!important;
}
.footer-links a{
  color:#ffffff;
  text-decoration: none;
  margin: 0 10px 0 10px;
}
