.buttonStyle{
  background: none!important;
  color: #ffffff!important;
  text-decoration: underline!important;
  text-transform: none!important;
  font-family: amplitude-light,Arial Narrow,arial !important;
  font-size: 14px!important;
  padding: 0!important;
  letter-spacing: unset!important;
  display: initial!important;
  text-decoration: none!important;
  margin-right: 10px!important;
}
.popupStyle{
  width: 800px;
  /*overflow: hidden!important;*/
}
/*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {*/
/*  .popupStyle{*/

/*    overflow: hidden!important;*/
/*  }*/
/*}*/
.termsHeading{
  font-family: amplitude-light,Arial Narrow,arial !important;
  font-size: 48px!important;;
  font-weight: normal;
  margin: 0;
  height: 1.5em;
  color: #233545;
  display: block;
  line-height: 1.5em;
  text-overflow: ellipsis;
  width: 92%;
  overflow: hidden;
  text-align: center;
  padding-left: 39px;
}

menu, ol, ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

cite, em, var, address, dfn {
  font-style: normal;
}
.terms-of-user-container{
  /*overflow-y: scroll;*/
  width: 900px;
  /*overflow-x: hidden;*/
  margin-top: -25px;
  padding-right: 10px;
}
