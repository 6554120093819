.accordianContent{
  margin: 0 auto;
  /*margin-bottom: 70px;
  margin-left: 40px;*/
  font-family: amplitude-light, Arial, Helvetica, sans-serif;
  line-height: 1.4em;
  font-size: 1.3em;
}
.accordian-heading-design{
  margin: 0 auto;
 /* background-color: #161616!important;*/
  padding-right: 0px!important;

}
/*.accordianComponent>div>div{
  background-color: #161616!important;
}*/

.accordianComponent .jpmuitk-icon-down-arrow{
  color:var(--iconsColor);
  margin: 25px!important;
  font-size: 0px!important;
}
.accordianComponent a{
  color:var(--iconsColor);
  color:#f1a200!important;
}
.accordianComponent .jpmuitk-icon-message{
  color:var(--iconsColor);
  color:#f1a200!important;
}
.accordianContent div{
  background-color: var(--accordianBackground);
  background-color: #242526!important;

  color:var(--fontColor);
  color: #f2f4f6!important;
}
.accordianComponent> div{
  /*margin-bottom: 20px;*/
}
hr {
  border: none;
  border-top: 1px solid var(--accordianSeparatorColor);
  border-top: 1px solid #e06519;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 650px;
}
.complain-container{
  border: 1px solid #757878;
  padding: 44px;
}
.accordianHeading{
  font-size: 24px;
  font-weight: 100;
  color:var(--fontColor);
  color:#f2f4f6!important;
  position: relative;
  font-family: amplitude-light, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
 /* margin: 45px auto 10px auto;*/
  font-size: 24px;
}
.accordianPannelColor{
  background-color: #f8f8f9;
}

.complaint-contact {
  max-width: 300px;
  margin: 0 auto;
  border: 1px solid #c9c0bc;
  padding: 40px;
  font-family: "amplitude-light","Arial Narrow",arial;
  font-size: 1em;
  line-height: 1.4;
}
.infoText{
  display: block;
}
.complain-content-list{
  margin: 0;
  padding: 0;
}
.complain-content-list li{
  list-style-type: none;
}
p{
  font-family: "amplitude-light","Arial Narrow",arial!important;
  font-size: 1.3em;
  line-height: 1.4em;
}
.mail-icon-container{

}
.accordian-spinner-style{
  /*width:20px!important;*/
}
