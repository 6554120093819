.find-products{
  width:100%;
  background-color: white;
  height:50%;
}
.complaints{
  width:100%;
  background-color: #f8f8f9;
  height:50%;
}
#isin-search{
  vertical-align: middle;
  line-height: 71px;
}
.searchDiv{
  width: 800px;
  height:60px;
  border:1px solid black;
  display:inline-block;
}
.searchPanel{
  padding:20px;
  text-align:center;
  color:var(--primaryTextColor);
  /*IE Fallback*/
  color:#f2f4f6!important;
  background-color: rgba(0, 0, 0, 0)!important;
  animation-name: searchPanelFadein;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  overflow: hidden!important;
}
@keyframes searchPanelFadein {
  from {opacity: 0}
  to {opacity: 1}
}
.searchBoxDecore{
  fontSize: 27px;
  lineHeight: 2em;
  paddingLeft: 5px;
  width:795px;
  top:2px;
}
.error-msg{
  text-align: center;
  padding: 46px 54px;
  border: 1px solid rgba(243, 203, 165, 0.8);
  max-width: 376px;
  margin: 0 auto;
  background: rgba(243, 203, 165, 0.3);
  display:none;
}
.download{
  text-align: center;
  padding: 46px 54px;
  border: 1px solid rgba(185,243,165,0.8);
  max-width: 376px;
  margin: 0 auto;
  background: rgba(185,243,165,0.1);
  display:none;
}
.searchBoxDecore{
  fontSize: 27px;
  lineHeight: 2em;
  paddingLeft: 5px;
  width:795px;
  top:2px;
}
.searchContainer{
  width:100%;
  height:80%;
}
.search-page-headings{
  font-size: 40px;
  margin: 0 auto;
  width: 65%;
  text-align:center;
  /*border-bottom: 1px solid #c9c0bc;
  line-height:0.1em;*/
}

.heading-text{
  color:var(--primaryTextColor);
  padding:25px;
}
.accordian-heading-text{
  color:var(--primaryTextColor);
  color:#f2f4f6!important;
  padding:0 25px 0 25px;
}
searchForm input{
  font-size: 40px;
}
.footerPosition{
  z-index: 10;
}
.accordianComponent{
  margin: 0 auto;
}
#complaintsContainer{
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 15px;
  padding-bottom: 26px;
  background-color: var(--accordianBackground);
  margin-left: 15px;
  margin-right: 15px;
  background-color: #242526!important;
}

#complaintsLayout{
  padding-top:0px;
  margin-top: 15px;
  position: relative;
  /*background-color: #f8f8f9;*/
  animation-name: accordian-fadein;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}
@keyframes accordian-fadein {
  from {margin-top:-10px;opacity: 0}
  to {margin-top:15px;opacity: 1}
}
.search-component-holder{
  /*background-color: #ffffff;*/
}
.searchFormContainer div label{
  color:var(--primaryTextColor);
  font-size: 19px;
  color: #f2f4f6;
}
.complain-card{
  background-color: rgba(0,0,0,.10)!important;
  border: none!important;
  max-width: 1000px;
  margin: 0 auto;
  max-height: 455px;
  overflow-y: auto!important;
  margin-top: 15px!important;
}

.seperator{
  border-top: 5px solid var(--separatorColor);
  border-top:5px solid #e06519!important;
  width:30%;
  background-color: rgba(227,227,227,0);
  animation-name: inlarge;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

.devider{
  margin-top: 70px;
}


/*Search box sizing */
.searchFormContainer{
  background-color: var(--searchBackgroundColor);
  background-color: rgba(58,34,6,0)!important;
  margin: 15px auto 35px auto;
  width: 540px;
  border: 1px solid var(--iconsColor);
  border:1px solid #f1a200!important;
  padding: 5px;
}

.searchFormContainer div div{
  height: 35px;
  margin-top: -2px;
}
.searchFormContainer input{
  font-size: 22px!important;
  z-index: 2;
  padding-bottom: 5px;
}
.searchFormContainer .jpmuitk-icon-search{
  font-size: 20px!important;
}
.searchFormContainer .jpmuitk-icon-close{
  font-size: 20px!important;
}
.searchFormContainer button{
  margin-bottom: 7px;
  margin-right: 5px;
  width: 205px;
  z-index: 3;
}
.spinner-style{
  margin: 0 auto;
  width: 30px!important;
  margin-top: 20px;
  margin-bottom: 20px;
}
/*scrollbar*/

/* width */
::-webkit-scrollbar,-moz-osx-scrollbar  {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track,-moz-osx-scrollbar-track {
  background: var(--trackBackground);
}

/* Handle */
::-webkit-scrollbar-thumb,-moz-osx-scrollbar-thumb {
  background: var(--trackthumbColor);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--trackthumbHover);
}

.search-form-label {
  width: 250px;
  float: left;
}

.search-input-style {
  float: left;
  width: 283px;
  background: #ffffff;
}

.search-input-style div {
  box-sizing: revert!important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-input-style div {
    box-sizing: content-box!important;
  }
}
.form-style > div > div {
  background-color: transparent!important;
  border-bottom: none!important;
}

.search-label-text {
  color: var(--primaryTextColor);
  color: #f2f4f6!important;
  padding: 0px;
  font-size: 25px;
}
