/*Light theme*/
html[data-theme="light"] {
  --primaryBackground: #d3d3d3!important;
  --primaryTextColor: #ffffff!important;
  --selectButtonTextColor: #000000!important;

  --separatorColor: #ffffff!important;
  --accordianSeparatorColor: #9a9a9a!important;
  --iconsColor: #3B5167!important;
  --searchBackgroundColor: rgba(58, 34, 6, 1)!important;

  --trackBackground:#F8F8F9!important;
  --trackthumbColor:#3A2206!important;
  --trackthumbHover:#9a9a9a!important;

  --headerBackground:#3A2206!important;
  --footerBackground:#3A2206!important;
  --accordianBackground:#F8F8F9!important;
  --fontColor:#080502!important;

  --mainBackground: -webkit-linear-gradient(to top, rgba(27,67,91,0.8),rgba(27,67,91,0.6)),url("../../assets/test-background.jpg");
  --mainBackgroundImageMoz: -moz-linear-gradient(to top, rgba(27,67,91,0.8),rgba(27,67,91,0.6)), url("../../assets/test-background.jpg");
  --mainBackgroundImageMs: -ms-linear-gradient(to top, rgba(27,67,91,0.8),rgba(27,67,91,0.6)), url("../../assets/test-background.jpg");
  --mainBackgroundImageLin: linear-gradient(to top, rgba(27,67,91,0.8),rgba(27,67,91,0.6)), url("../../assets/test-background.jpg");
}
/*Dark theme This is default*/
html {
 --primaryBackground: #2670A9!important;
 --primaryTextColor: #f2f4f6!important;

  --selectButtonTextColor: #ffffff!important;
  --separatorColor:#E06519!important;
  --accordianSeparatorColor:#E06519!important;
  --iconsColor: #f1a200!important;
  --searchBackgroundColor: rgba(58, 34, 6, 0)!important;

  --trackBackground:#161616!important;
  --footerBackground:#161616!important;
  --trackthumbColor:#E06519!important;
  --trackthumbHover:#f1a200!important;

  --headerBackground:#161616!important;
  --accordianBackground:#242526!important;
  --fontColor:#f2f4f6!important;
  --mainBackground: -webkit-linear-gradient(to top, rgba(36, 37, 38,0.6), rgba(36, 37, 38,0.8),rgba(0, 0, 0,1)),url("../../assets/test-background.jpg");
  --mainBackgroundImageMoz: -moz-linear-gradient(to top, rgba(36, 37, 38,0.6), rgba(36, 37, 38,0.8),rgba(0, 0, 0,1)), url("../../assets/test-background.jpg");
  --mainBackgroundImageMs: -ms-linear-gradient(to top, rgba(36, 37, 38,0.6), rgba(36, 37, 38,0.8),rgba(0, 0, 0,1)), url("../../assets/test-background.jpg");
  --mainBackgroundImageLin: linear-gradient(to top, rgba(36, 37, 38,0.6), rgba(36, 37, 38,0.8),rgba(0, 0, 0,1)), url("../../assets/test-background.jpg");
}
